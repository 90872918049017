export function timeFormat(time, format = 'date') {
    const symbolType = '-'

    const date = new Date(time)
    const Y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    const H = getFullTime(date.getHours())
    const i = getFullTime(date.getMinutes())
    const s = getFullTime(date.getSeconds())

    if (format == "date") {
        return Y + symbolType + m + symbolType + d
    }

    if (format == 'datetime') {
        return Y + symbolType + m + symbolType + d + " " + H + ":" + i + ":" + s
    }
}

export function getFullTime(time) {
    return time < 10 ? "0" + time.toString() : time.toString()
}

const time = new Date()

// 获取当前时间
export const GetCurrentTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds())
}

//获取今天结算时间
export const GetTodayEndTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + "23" + ":" + "59" + ":" + "59")
}
// 获取昨天开始时间
export const GetYesterdayTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + (time.getDate() - 1) + " " + "00" + ":" + "00" + ":" + "00")
}

// 获取昨天结束时间
export const GetYesterdayEndTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + (time.getDate() - 1) + " " + "23" + ":" + "59" + ":" + "59")
}

export const GetTodayTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + "00" + ":" + "00" + ":" + "00")
}

export const GetCurrentMonthTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + 1 + " " + "00" + ":" + "00" + ":" + "00")
}

export function format(time) {
    const symbolType = '-'

    const date = new Date(time)
    const Y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    const H = getFullTime(date.getHours())
    const i = getFullTime(date.getMinutes())
    const s = getFullTime(date.getSeconds())

    return Y + symbolType + m + symbolType + d + " " + H + ":" + i + ":" + s
}

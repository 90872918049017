<script setup>
import { onMounted, ref, getCurrentInstance, reactive, } from 'vue';
import { fixed } from "../../tools/utils"
import { Document, Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
// import { GetYesterdayTime, ToYesterdayTime } from "../../tools/time"
import {GetTodayTime, GetCurrentTime} from "../../tools/time"


const { appContext } = getCurrentInstance()

// 加载
const loading = ref(true)
const tableloading = ref(true)

// 面板数据
const panelData = ref({
  pay_number: 0,
  pay_amount: 0,
  fee_mount: 0
})

// 查询面板数据
const queryPanelData = (data) => {
  loading.value = true
  appContext.config.globalProperties.$api.trade.queryPanel(data).then(r => {
    if (r.status == 200) {
      panelData.value = r.data
    } else {
      if (r.message)
        ElMessage.error('查询失败，服务器内部错误')
      else
        ElMessage.error(r.error.message)
    }
    queryTable(data)
  })
}

// 表格数据
const table = ref()
const pageinfo = ref({ count: 0, total: 0, perPage: 0, currentPage: 1, totalPages: 0 })

const queryTable = (data) => {
  tableloading.value = true
  appContext.config.globalProperties.$api.trade.queryData(data).then(r => {
    if (r.status == 200) {
      r.data.forEach(item => {
        item.created_end = searchInfo.datetime.length !== 0 ? searchInfo.datetime[1] : GetCurrentTime()
      })
      table.value = r.data
      //有返回分页信息时使用
      // pageinfo.value = r.pagination
    } else {
      if (r.message)
        ElMessage.error('查询失败，服务器内部错误')
      else
        ElMessage.error(r.error.message)
    }
    loading.value = false
    tableloading.value = false
  })
}
// 有返回分页信息时切换页码
// const pageChange = (page) => {
//   let info = searchInfo
//   if (Object.keys(info).length == 0) {
//     queryTable({ page: page })
//   } else {
//     info.page = page
//     info.time_start = info.datetime[0]
//     info.time_end = info.datetime[1]
//     queryTable(info)
//   }
// }


// 无返回分页信息时切换页码
const pageChange = (page) => {
  let info = searchInfo
  if (page > pageinfo.value.currentPage) {
    pageinfo.value.currentPage++
  } else if (page < pageinfo.value.currentPage) {
    pageinfo.value.currentPage--
  }
  if (Object.keys(info).length == 0) {
    queryTable({ page: page })
  } else {
    info.page = page
    info.time_start = info.datetime[0]
    info.time_end = info.datetime[1]
    queryTable(info)
  }
}


// 搜索
const searchInfo = reactive({
  datetime: [GetTodayTime(), GetCurrentTime()]
})
const handleSearch = () => {
  if (searchInfo.datetime) {
    const time_start = searchInfo.datetime[0].indexOf(' ') > 0 ? searchInfo.datetime[0] : searchInfo.datetime[0] + ' 00:00:00'
    const time_end = searchInfo.datetime[1].indexOf(' ') > 0? searchInfo.datetime[1] : searchInfo.datetime[1] + ' 23:59:59'
    let searchData = { ...searchInfo, time_start, time_end }
    delete searchData.datetime
    queryPanelData(searchData)
  } else {
    queryPanelData(searchInfo)
  }
}


// 代理商
const options = ref()
// 查询代理商
const QueryAgents = () => {
  // appContext.config.globalProperties.$api.trade.queryAgents({ pageSize: 999 }).then(r => {
  appContext.config.globalProperties.$api.agentsList({ parent_id: "null", pageSize: 999 }).then(r => {
    if (r.status == 200) {
      options.value = r.data.map(item => {
        return { value: item.id, label: item.company }
      })
    }
  })
}

const handleChange = (value) => {
  if (value != null) {
    searchInfo.agent_id = value[value.length - 1]
  }
}

onMounted(() => {
  queryPanelData({ time_start: GetTodayTime(), time_end: GetCurrentTime() })
  querySettlement()
  QueryAgents()
})

const hanldeExportExcel = async () => {
  const time_start = searchInfo.datetime[0].indexOf(' ') > 0 ? searchInfo.datetime[0] : searchInfo.datetime[0] + ' 00:00:00'
  const time_end = searchInfo.datetime[1].indexOf(' ') > 0 ? searchInfo.datetime[1] : searchInfo.datetime[1] + ' 23:59:59'
  let serchDate = {...searchInfo, time_start, time_end}
  delete serchDate.datetime
  await appContext.config.globalProperties.$api.trade.download(serchDate, "交易数据")
}

// 结算通道
const settlementOptions = ref()
const querySettlement = () => {
  appContext.config.globalProperties.$api.payment.paymentList().then(re => {
    if (re.status == 200) {
      settlementOptions.value = re.data.data
    }
  })
}

const casca = ref()
const handleReset = () => {
  Object.keys(searchInfo).forEach(item => {
    if (item !== "datetime") {
      searchInfo[item] = ""
      searchInfo.datetime = [GetTodayTime(), GetCurrentTime()]
    }
  })
  casca.value.panel.clearCheckedNodes()
  queryPanelData({ time_start: GetTodayTime(), time_end: GetCurrentTime() })
}
</script>

<template>
  <div v-loading="loading">
    <el-card shadow="never">
      <el-form :inline="true" :model="searchInfo" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="8">
            <el-form-item label="结算通道：">
              <el-select v-model="searchInfo.payment_channel_id" filterable placeholder="请选择结算通道" clearable>
                <el-option v-for="(item, index) in settlementOptions" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="代理商：">
              <el-cascader :options="options" ref="casca" @change="handleChange" clearable filterable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商户名称：">
              <el-input v-model="searchInfo.merchant_name" placeholder="请输入商户名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="商户号：">
              <el-input v-model="searchInfo.merchant_no" placeholder="请输入商户号" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="起止时间：">
              <el-date-picker v-model="searchInfo.datetime" value-format="YYYY-MM-DD" type="daterange" align="right" unlink-panels
                              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div class="btn-box">
              <el-button type="primary" @click="handleReset()">重置</el-button>
              <el-button type="primary" @click="handleSearch()" :icon="Search">查询</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <div class="card-panel">
      <el-card shadow="hover" class="card">
        <template #header>
          <div class="card-header">
            <span>交易笔数</span>
          </div>
        </template>
        <div class="card-body">
          <p>{{ panelData.pay_number }}</p>
          <p>交易笔数</p>
        </div>
      </el-card>
      <el-card shadow="hover" class="card">
        <template #header>
          <div class="card-header">
            <span>交易总流水</span>
          </div>
        </template>
        <div class="card-body">
          <p>{{ fixed(panelData.pay_amount) }}</p>
          <p>交易总流水</p>
        </div>
      </el-card>
      <el-card shadow="hover" class="card">
        <template #header>
          <div class="card-header">
            <span>总手续费</span>
          </div>
        </template>
        <div class="card-body">
          <p>{{ fixed(panelData.fee_mount) }}</p>
          <p>总手续费</p>
        </div>
      </el-card>
      <el-card shadow="hover" class="card">
        <template #header>
          <div class="card-header">
            <span>总结算金额</span>
          </div>
        </template>
        <div class="card-body">
          <p>{{ fixed(panelData.pay_amount - panelData.refund_mount - panelData.fee_mount) }}</p>
          <p>总结算金额</p>
        </div>
      </el-card>
    </div>

    <div class="content">
      <div class="export">
        <el-button type="success" link :icon="Document" @click="hanldeExportExcel()">导出Excel</el-button>
      </div>
      <el-table :data="table" style="width: 100%" v-loading="tableloading" size="small">
        <el-table-column prop="merchant_no" label="商户号" width="120" />
        <el-table-column prop="first_agent_name" label="渠道商" width="120" />
        <el-table-column prop="settlement_amount" label="结算通道" width="120" />
        <el-table-column prop="second_agent_name" label="合作商" width="120" />
        <el-table-column prop="merchant_name" label="商户名" width="200" />
        <el-table-column prop="created_at" label="交易日期" width="150">
          <template #default="scope">
            <span>{{ scope.row.time_start }} 至 {{ scope.row.time_end }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_amount" label="交易流水" />
        <el-table-column prop="pay_number" label="订单量" />
        <el-table-column prop="refund_mount" label="退款金额" />
        <el-table-column prop="fee_mount" label="手续费">
          <template #default="scope">
            <span>{{ fixed(scope.row.fee_mount) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结算金额">
          <template #default="scope">
            <span>{{ fixed(scope.row.pay_amount - scope.row.refund_mount - scope.row.fee_mount) }}</span>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
      <div class="pagination">
        <!-- 有返回分页信息时使用 -->
        <!-- <el-pagination background layout="total, prev, pager, next" :total=pageinfo.total @current-change="pageChange" :page-size=pageinfo.perPage :current-page=pageinfo.currentPage /> -->

        <el-pagination background layout="prev" :total=pageinfo.total @current-change="pageChange"
          :page-size=pageinfo.perPage :current-page=pageinfo.currentPage />
        <p>{{ pageinfo.currentPage }}</p>
        <el-pagination background layout="next" :total=pageinfo.total @current-change="pageChange"
          :page-size=pageinfo.perPage :current-page=pageinfo.currentPage />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.btn-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.card-panel {
  padding: 8px 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .card {
    width: 24%;

    .card-header {
      font-size: 18px;
      font-weight: bold;
    }

    .card-body {
      font-size: 14px;

      p {
        margin: 6px;
      }

      p:first-child {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

.content {
  margin-top: 1.24rem;

  .pagination {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
  }

  .export {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }
}
</style>
